//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import constant from '~/const';

export default {
  data() {
    return {
      model: {
        fileLengthLimit: '',
        filesLengthLimit: '',
        filesCountLimit: '',
      },
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('settingsUploadOptions', ['data']),
  },

  watch: {
    data() {
      const { fileLengthLimit, filesLengthLimit, filesCountLimit } = this.data;
      this.model.fileLengthLimit = fileLengthLimit;
      this.model.filesLengthLimit = filesLengthLimit;
      this.model.filesCountLimit = filesCountLimit;
    },
  },

  methods: {
    ...mapActions('common', ['confirmAction', 'setGeneralProgress', 'setSuccessNotification']),
    ...mapActions('settingsUploadOptions', ['loadData', 'updateOptions']),

    apply() {
      this.confirmAction({
        title: 'Are you sure you want to update upload options?',
        callback: async () => {
          const { model } = this;
          this.setGeneralProgress(true);
          await this.updateOptions(model);
          await this.loadData();
          this.setGeneralProgress(false);
          this.setSuccessNotification('Upload options updated successfully');
        },
      });
    },
  },
};
