//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SettingsTechnicalWork from './SettingsTechnicalWork/SettingsTechnicalWork.vue';
import SettingsVerification from './SettingsVerification/SettingsVerification.vue';
import SettingsUploadOptions from './SettingsUploadOptions/SettingsUploadOptions.vue';

export default {
  components: {
    SettingsTechnicalWork,
    SettingsVerification,
    SettingsUploadOptions,
  },
};
