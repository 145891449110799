//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import constant from '~/const';

export default {
  data() {
    return {
      model: {
        verificationType: null,
        verificationAutoType: null,
      },
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('settingsVerification', ['data']),
  },

  watch: {
    data() {
      const { verificationType, verificationAutoType } = this.data;
      this.model.verificationType = verificationType;
      this.model.verificationAutoType = verificationAutoType;
    },
  },

  methods: {
    ...mapActions('common', ['confirmAction', 'setGeneralProgress', 'setSuccessNotification']),
    ...mapActions('settingsVerification', ['loadData', 'updateVerificationOptions']),

    apply() {
      this.confirmAction({
        title: 'Are you sure you want to update verification options?',
        callback: async () => {
          const { model } = this;
          this.setGeneralProgress(true);
          await this.updateVerificationOptions(model);
          await this.loadData();
          this.setGeneralProgress(false);
          this.setSuccessNotification('Verification options updated successfully');
        },
      });
    },
  },
};
